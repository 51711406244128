<template>
  <div>
    <CRow>
      <CCol xl="12">
        <CForm @submit="submitFilterValues">
          <CCard>
            <CButton
              @click="cardCollapse = !cardCollapse"
              tag="button"
              color="link"
              block
              class="flex flex--space-between text-left shadow-none card-header"
            >
              <h5 class="m-0">Filtros</h5>
              <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
            </CButton>
            <CCollapse :show="cardCollapse">
              <CCardBody>
                <CRow>
                  <CCol xl="12">
                    <label class="filter-label">{{
                      messages.filters.area
                    }}</label>
                    <FiltersAreaCombo :activeElements="activeItems" />
                    <div v-if="areaError" class="error-message error-message">
                      {{ messages.validation.filterAreaCombo }}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xl="3">
                    <label class="filter-label">FY</label>
                    <FilterFY />
                  </CCol>
                  <CCol xl="6">
                    <label class="filter-label">{{
                      messages.filters.period
                    }}</label>
                    <FilterMonthsRange />
                  </CCol>
                  <CCol xl="3">
                    <label class="filter-label">{{
                      messages.filters.cumulativeOrNot
                    }}</label>
                    <FilterIsCumulative />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CButton type="submit" color="primary">Mostrar</CButton>
              </CCardFooter>
            </CCollapse>
          </CCard>
        </CForm>
        <TableVolumeBonus
          v-if="showTable"
          :fields="tableFields"
          :items="tableItems"
          :showLoader="showLoader"
          :tableTitle="tableTitle"
        >
          <span slot="tableTitle">{{ tableTitle }}</span>
        </TableVolumeBonus>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import formatNum from "@/utils/formatNum";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import calculatePeriod from "@/helpers/calculatePeriod";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filters-area-combo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );

const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filter-months-range" */ "@/components/molecules/FilterMonthsRange"
  );

const FilterIsCumulative = () =>
  import(
    /* webpackChunkName: "filter-is-cumulative" */ "@/components/molecules/FilterIsCumulative"
  );

const TableVolumeBonus = () =>
  import(
    /* webpackChunkName: "table-volume-bonus" */ "@/components/organisms/TableVolumeBonus"
  );

export default {
  name: "VolumeBonus",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    FilterIsCumulative,
    TableVolumeBonus,
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      selectedArea: null,
      selectedPeriod: null,
      selectedFY: null,
      showLoader: false,
      tableFields: null,
      tableItems: null,
      tableTitle: null,
      showTable: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {
    makeDefaultQuery() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const areaKey = defaultArea.key;
        const areaParam = {
          countryCode: "",
          regionalCode: "",
          aspm: "",
          concessionCode: "",
        };
        areaParam[areaKey] = defaultArea.value;
        const period = calculatePeriod(currentFY);
        const defaults = {
          ...areaParam,
          fiscalYear: currentFY,
          sinceMonth: period.start,
          upToMonth: period.end,
          isCumulative: 0,
        };
        const areaVal = defaults.countryCode
          ? this.messages.areaByCode[defaults.countryCode]
          : defaults.regionalCode
          ? this.messages.areaByCode[defaults.regionalCode]
          : defaults.aspm
          ? defaults.aspm
          : defaults.concessionCode
          ? defaults.concessionCode
          : "";
        this.setTableTitle(
          areaVal,
          defaults.fiscalYear,
          defaults.sinceMonth,
          defaults.upToMonth
        );
        this.makeApiQuery(defaults).then((data) => {
          this.processTableData(data);
        });
      });
    },
    submitFilterValues(ev) {
      ev.preventDefault();
      const areaParam = this.$store.getters.getFiltersAreaComboParams;
      let validated = false;
      if (!areaParam) {
        this.areaError = true;
      } else {
        this.areaError = false;
        validated = true;
      }
      if (validated) {
        this.showLoader = true;
        this.showTable = true;
        const params = {
          ...areaParam,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          isCumulative: this.$store.getters.getFilterIsCumulativeValue,
        };
        const areaVal = params.countryCode
          ? this.messages.areaByCode[params.countryCode]
          : params.regionalCode
          ? this.messages.areaByCode[params.regionalCode]
          : params.aspm
          ? params.aspm
          : params.concessionCode
          ? params.concessionCode
          : "";
        this.setTableTitle(params);
        this.makeApiQuery(params).then((data) => {
          this.processTableData(data);
        });
      }
    },
    async makeApiQuery(params) {
      const response = await Api.sendPost(
        "commercial/bonusVolumeReport",
        params
      );
      if (response.status === 200) {
        if (response.data.code === 200) {
          return response.data.data;
        }
      }
    },
    /* async makeApiQuery(params) {
      const response = await Api.testPost("nibsa/bonoVolumeReport", params);
      return response;
    }, */
    setTableTitle(params) {
      const areaName = getAreaName(params, this.messages);
      const selectedSinceMonth = this.messages.filters.months[params.sinceMonth];
      const selectedUpToMonth = this.messages.filters.months[params.upToMonth];
      const period = selectedSinceMonth === selectedUpToMonth ? selectedSinceMonth : `de ${selectedSinceMonth} a ${selectedUpToMonth}`
      this.tableTitle = `${areaName} ${areaName && ' - '} ${period} / ${formatFiscalYear(params.fiscalYear)}`;
    },
    processTableData(data) {
      this.tableFields = [];
      this.tableItems = [];
      if (data.length > 0) {
        for (let prop in data[0]) {
          if (prop === "month") {
            this.tableFields.unshift({
              key: prop,
              label: this.messages.volumeBonusTable[prop].toUpperCase(),
            });
          } else {
            if (prop !== "concession") {
              this.tableFields.push({
                key: prop,
                label: this.messages.volumeBonusTable[prop].toUpperCase(),
              });
            } else {
              this.tableFields.push({
                key: "concessionCode",
                label:
                  this.messages.volumeBonusTable.concessionCode.toUpperCase(),
              });
              this.tableFields.push({
                key: "businessName",
                label:
                  this.messages.volumeBonusTable.businessName.toUpperCase(),
              });
            }
          }
        }
        data.map((elem) => {
          let row = {
            _classes: "focus",
          };
          for (let prop in elem) {
            if (prop !== undefined) {
              if (prop === "concession") {
                row.concessionCode = elem.concession.concessionCode;
                row.businessName = elem.concession.businessName;
              } else if (prop === "month") {
                row.month =
                  this.messages.filters.months[elem[prop]].toUpperCase();
              } else {
                let value;
                value =
                  value !== null && !isNaN(elem[prop])
                    ? formatNum.get(elem[prop], 2)
                    : "";
                const sufix = prop === "completion" ? " %" : " €";
                row[prop] = `${value}${sufix}`;
              }
            }
          }
          this.tableItems.push(row);
        });
        this.showLoader = false;
      }
    },
  },
  created() {
    // this.makeDefaultQuery();
  },
};
</script>
